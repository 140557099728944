<template>
  <g fill="none" fill-rule="evenodd">
    <g>
      <g>
        <g>
          <path
            fill="#F16D24"
            d="M0 16.948C0 7.588 7.587 0 16.948 0c9.36 0 16.947 7.588 16.947 16.948s-7.588 16.947-16.947 16.947C7.588 33.895 0 26.308 0 16.948"
            transform="translate(-236 -1699) translate(26 1695) translate(210.073 4.108)"
          />
          <path
            fill="#EF6421"
            d="M25.814 2.483c1.688 2.635 2.666 5.767 2.666 9.129 0 9.36-7.587 16.947-16.948 16.947-3.249 0-6.287-.916-8.866-2.503 3.013 4.703 8.284 7.82 14.282 7.82 9.36 0 16.947-7.588 16.947-16.948 0-6.11-3.233-11.463-8.08-14.445"
            transform="translate(-236 -1699) translate(26 1695) translate(210.073 4.108)"
          />
          <path
            fill="#F2824E"
            d="M2.304 18.736C2.304 10.046 9.35 3 18.038 3c5.08 0 9.595 2.407 12.473 6.143-2.716-4.717-7.809-7.893-13.644-7.893-8.69 0-15.736 7.045-15.736 15.735 0 3.61 1.22 6.937 3.263 9.592-1.33-2.309-2.09-4.987-2.09-7.842"
            transform="translate(-236 -1699) translate(26 1695) translate(210.073 4.108)"
          />
          <path
            fill="#303030"
            d="M11.954 13.794c0 1.162-.561 2.105-1.251 2.105-.688 0-1.249-.943-1.249-2.105 0-1.163.561-2.105 1.249-2.105.69 0 1.25.942 1.25 2.105M24.44 13.794c0 1.162-.558 2.105-1.248 2.105s-1.25-.943-1.25-2.105c0-1.163.56-2.105 1.25-2.105s1.248.942 1.248 2.105"
            transform="translate(-236 -1699) translate(26 1695) translate(210.073 4.108)"
          />
          <path
            fill="#5E4939"
            d="M23.893 24.48c.438.424 1.138-.045.911-.61-1.253-3.129-4.31-5.34-7.888-5.34-3.515 0-6.531 2.138-7.821 5.184-.237.555.469 1.037.902.616 2.48-2.404 7.747-5.826 13.896.15"
            transform="translate(-236 -1699) translate(26 1695) translate(210.073 4.108)"
          />
          <path
            fill="#303030"
            d="M14.224 10.865L13.087 12.001 9.295 8.209 10.433 7.073 14.224 10.865M20.808 12.001L19.672 10.865 23.464 7.073 24.599 8.209 20.808 12.001"
            transform="translate(-236 -1699) translate(26 1695) translate(210.073 4.108)"
          />
        </g>
      </g>
    </g>
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
